import React from "react";
import Confetti from "react-confetti";
import * as Tone from "tone";
import unmuteIosAudio from "unmute-ios-audio";

import useWindowSize from "./useWindowSize";

import gift1 from "./img/gift-1.jpg";
import gift2 from "./img/gift-2.jpg";

// const speed = 2;
const speed = 0.5;
const max = 100.0;
const waitTime = 20.0;
const waitAt = max * 0.85;
const speedBonusAfterWait = 1.4;

const noteStart = 100;
const noteEnd = 600;
const prompts = [
    "Searching for gift",
    "Adding to cart",
    "Processing purchase",
    "Shipping gift",
    "Shipping gift",
    "Ringing doorbell",
    "Ringing doorbell again",
    "Wrapping gift",
    "Hiding gift",
    "Loading gift",
];

const doneOverride = false;

function ConfettiCanvas() {
    const { width, height } = useWindowSize();
    return (
        <Confetti
            width={width}
            height={height}
            style={{ zIndex: 10 }}
            colors={["red", "green", "blue", "purple"]}
        />
    )
}

class App extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toneFast: false,
            started: false,
            actual: 0.0,
            wait: 0.0
        }
        this.synth = new Tone.Synth().toDestination();
        this.poly = null;
        this.startTimer = this.startTimer.bind(this);
        this.timerFunction = this.timerFunction.bind(this);
    }

    componentDidMount() {
        this.preloadImage(gift1);
        this.preloadImage(gift2);
        unmuteIosAudio();
    }

    preloadImage(url) {
        if(!this.imageCache) {
            this.imageCache = [];
        }
        let image = new Image();
        image.src = url;
        this.imageCache.push(image);
    }

    playSynth() {
        console.log("Play");
        const now = Tone.now();
        const progress = this.state.actual;
        if(progress >= 100.0) {
            return;
        }
        const currentNote = Math.ceil((progress / max * (noteEnd - noteStart)) + noteStart);
        this.synth.triggerAttackRelease(`${currentNote}`, "16n", now);
    }

    playDone() {
        if(this.poly) {
            return;
        }
        this.poly = new Tone.PolySynth(Tone.Synth).toDestination();
        const now = Tone.now();
        this.poly.triggerAttack("D4", now);
        this.poly.triggerAttack("F4", now + 0.5);
        this.poly.triggerAttack("A4", now + 1);
        this.poly.triggerAttack("C5", now + 1.5);
        this.poly.triggerAttack("E5", now + 2);
        this.poly.triggerRelease(["D4", "F4", "A4", "C5", "E5"], now + 4);
    }

    startTimer() {
        Tone.start();
        this.setState({ started: true, toneFast: false });
        this.timer = setInterval(() => {
            this.updateProgress();
        }, 100);
        this.slowPlayToggling = false;
        this.synthTimer = setInterval(this.timerFunction, 1000);
    }

    timerFunction() {
        this.slowPlayToggling = !this.slowPlayToggling;
        if(!this.slowPlayToggling && !this.state.toneFast) {
            return;
        }
        this.playSynth();
    }

    componentWillUnmount() {
        if(this.timer) {
            clearInterval(this.timer);
        }
        if(this.synthTimer) {
            clearInterval(this.synthTimer);
        }
    }

    updateProgress() {
        this.setState((prevState) => {
            let actualIncrease = Math.random() * speed;
            let waitIncrease = Math.random() * speed;
            if(prevState.actual > waitAt && prevState.wait < waitTime) {
                actualIncrease = 0;
            } else {
                waitIncrease = 0;
            }
            let toneFast = false;
            if(prevState.actual > waitAt) {
                actualIncrease *= speedBonusAfterWait;
                if(prevState.wait >= waitTime) {
                    toneFast = true;
                }
            }
            const actual = Math.min(max, prevState.actual + actualIncrease);
            const wait = Math.min(waitTime, prevState.wait + waitIncrease);
            if(actual >= 100.0) {
                this.playDone();
            }
            return {
                actual,
                wait,
                toneFast
            };
        })
    }

    render() {
        const {
            started,
            actual,
            // wait
        } = this.state;

        const percentage = actual / max * 100;
        const currentPromptIndex = Math.floor(actual / max * prompts.length);
        const currentPrompt = prompts[currentPromptIndex];

        return (
            <React.Fragment>
                <div className="background"/>
                <ConfettiCanvas/>
                <div className="container-fluid">
                    <div className="content text-white text-center comic">
                        { !started && !doneOverride ? (
                            <React.Fragment>
                                <h1>
                                    Daneira is jarig!
                                </h1>
                                <button
                                    onClick={ this.startTimer }
                                    className="btn btn-success btn-lg mt-3"
                                >
                                    Receive present
                                </button>
                            </React.Fragment>
                        ) : actual < max && !doneOverride ? (
                            <React.Fragment>
                                <h1>
                                    { currentPrompt }...
                                </h1>
                                <h2 className="mt-3">
                                    ({ percentage.toFixed(0) }%)
                                </h2>
                                <div className="w-100">
                                    <div
                                        style={{
                                            width: percentage + "%",
                                            height: "5px",
                                            background: "white"
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h1>
                                    Your gift
                                </h1>
                                <h3 className="mt-3">
                                    Choose 1 from the following page:
                                </h3>
                                <a
                                    href="https://pinterest.com/daneira_smith/-t-a-t-t-o-o-s-/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-success btn-lg mt-3"
                                >
                                    Choose
                                </a>
                                {/*<div className="gift-container mt-3">*/}
                                {/*    <div>*/}
                                {/*        <img*/}
                                {/*            src={ gift1 }*/}
                                {/*            alt="Gift 1"*/}
                                {/*            className="img-gift"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*    <div>*/}
                                {/*        <img*/}
                                {/*            src={ gift2 }*/}
                                {/*            alt="Gift 2"*/}
                                {/*            className="img-gift"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default App;
