import {
    useState,
    useEffect,
    useMemo
} from "react";

function useWindowSize() {
    const isClient = useMemo(() => {
        return typeof window === "object";
    }, []);

    const getSize = useMemo(() => {
        return () => {
            return {
                width: isClient ? window.innerWidth : undefined,
                height: isClient ? window.innerHeight : undefined
            };
        }
    }, [isClient]);

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, [getSize, isClient]);

    return windowSize;
}

export default useWindowSize;
